.Background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #111111;
}

.SadFace {
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    font-size: 10vw;
    color: white;
    margin: 0;
    padding: 0;
    left: 10%;
    top: 10%;
}

.Text {
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5vw;
    font-weight: 300;
    color: white;
    margin: 0;
    padding: 0;
    /* left: 10%;
    top: 15%; */
}

.TextContainer {
    width: 50%;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 10%;
    top: 45%;
}

.ReloadLink {
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    color: white;
    left: 10%;
    top: 60%;
    font-size: 1vw;
    font-weight: 200;
    cursor: pointer;
}