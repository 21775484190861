.Background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #111;
}

.Welcome {
    position: absolute;
    width: max-content;
    height: auto;
    left: 50%;
    top: 50%;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
}

.Text {
    font-family: 'Montserrat', sans-serif;
    font-size: 4vw;
    font-weight: 200;
    text-align: center;
    color: white;
    margin: 0;
    padding: 0;
}

.TextContainer {
    width: 50%;
    position: absolute;
    margin: 0;
    padding: 0;
    left: 10%;
    top: 45%;
}

.SpinnerContainer {
    position: absolute;
    /* width: 100%; */
    height: 100%;
    left: 50%;
    top: 75%;
    margin: 0;
    padding: 0;
    transform: translate(-50%, 0);
    /* background-color: indianred; */
}

.Spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0;
    padding: 0;
}

.Cube1, .Cube2 {
    background-color: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
}

.Cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
    25% {
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5)
    }
    50% {
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
    }
    75% {
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)
    }
    100% {
        -webkit-transform: rotate(-360deg)
    }
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }
    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }
    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }
    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}